import Vue from 'vue';
import VueOnsen from 'vue-onsenui'; // This already imports 'onsenui'
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import ClickOutside from 'vue-click-outside';
import * as Sentry from '@sentry/vue';

import App from './App';
import middleware from './middleware';
import VueRx from 'vue-rx';
import VueMeta from 'vue-meta';
import frag from 'vue-frag';
import Noty from './component/noty/Noty';
import routes from './routes';
import VueWebsocket from './websocket/index';
import User from './User';
import numeral from 'numeral';
import ToggleSidebarButton from './component/ToggleSidebarButton';
import ToolbarPopoverMenu from './component/ToolbarPopoverMenu';
import ToolbarPopoverMenuItem from './component/ToolbarPopoverMenuItem';
import ProductCard from './component/ProductCard';

Vue.use(VueResource);
Vue.use(VueRouter);
Vue.use(VueOnsen);
Vue.use(require('@websanova/vue-upload'));

const moment = require('moment');
require('moment/locale/ru');
Vue.use(require('vue-moment'), {
    moment
});

Vue.use(VueRx);

Vue.use(VueMeta);

Vue.directive('frag', frag);

Array.prototype.unique = function() {
    let arr = [];
    for(let i = 0; i < this.length; i++) {
        if(!arr.includes(this[i])) {
            arr.push(this[i]);
        }
    }
    return arr;
};

Math.fmod = function(x, y) {
    x = x * 1000;
    y = y * 1000;

    return x % y;
};

// if (!window.Y_DEBUG) {
//     Vue.config.errorHandler = function (err, vm, info) {
//         let handler, current = vm;
//         if (vm.$options.errorHandler) {
//             handler = vm.$options.errorHandler;
//         } else {
//             while (current.$parent) {
//                 current = current.$parent;
//                 if (handler = current.$options.errorHandler) {
//                     break;
//                 }
//             }
//         }
//         if (handler) {
//             handler.call(current, err, vm, info);
//         } else {
//             let xhr = new XMLHttpRequest();
//             xhr.open('POST', '/api/report-js', true);
//             xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
//
//             let data = '';
//
//             data += 'name=' + encodeURIComponent(err);
//             data += '&href=' + window.location.href;
//             data += '&builddate=2024-09-23';
//             data += '&stack=' + encodeURIComponent(err.stack);
//
//             if (Vue.prototype.$user && Vue.prototype.$user.id) {
//                 data += '&user=' + Vue.prototype.$user.id;
//             }
//
//             xhr.send(data);
//         }
//     };
// }

Vue.prototype.$noty = new Noty();

class NewVueRouter extends VueRouter {
    findCurrentRoute(routes) {
        routes = routes || this.options.routes;
        let currentRoute = this.currentRoute;
        let child = null;

        for (let route of routes) {
            if (route.name === currentRoute.name) {
                return route;
            }

            if (route.children && route.children.length > 0) {
                child = this.findCurrentRoute(route.children);

                if (child) {
                    return child;
                }
            }
        }
    }

    getChildrenByPath(path) {
        if (!path) {
            return;
        }

        let route = this.findCurrentRoute();

        if (!route || !route.children || route.children.length < 1) {
            return;
        }

        for (let child of route.children) {
            if (child.path === path) {
                return child;
            }
        }
    }

    goRouteBack(steps = 1) {
        let route = this.currentRoute;
        this.push({name: route.matched[route.matched.length - (steps + 1)].name});
    }
}

const router = new NewVueRouter({
    mode: 'history',
    routes: routes,
});

Vue.prototype.$isDesktop = isDesktop;

Vue.prototype.$bus = new Vue({
    data: {
        referralManager: '',
        referralManagerStorage: '',
    },
});

middleware(router);

Vue.http.interceptors.push((request, next) => {
    let token = window.localStorage.getItem('token');
    request.headers = request.headers || {};

    if (token) {
        request.headers.set('X-Token', token);
    }

    request.headers.set('X-Builddate', '2024-09-23');

    next((response) => {
        if (response.status === 401 && !request.url.startsWith('/api/auth')) {
            window.localStorage.removeItem('token');
            return router.push({name: 'login'});
        }
    });
});

Vue.use(VueWebsocket);

Vue.prototype.$user = new User();

Vue.prototype.uuidv4 = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

Vue.prototype.$window = new Vue({
    data: {
        width: 0,
        height: 0,
        activeElement: null,
    },
    created() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.getWindowWidth);
            window.addEventListener('resize', this.getWindowHeight);
            document.addEventListener('focusin', this.focusin);
            document.addEventListener('focusout', this.focusout);

            //Init
            this.getWindowWidth();
            this.getWindowHeight();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getWindowWidth);
        window.removeEventListener('resize', this.getWindowHeight);
        document.removeEventListener('focusin', this.focusin);
        document.removeEventListener('focusout', this.focusout);
    },
    methods: {
        getWindowWidth() {
            this.width = document.documentElement.clientWidth;
            this.$emit('resize');
        },
        getWindowHeight() {
            this.height = document.documentElement.clientHeight;
            this.$emit('resize');
        },
        focusin(event) {
            this.activeElement = event.target;
        },
        focusout() {
            this.activeElement = null;
        },
        hasFocus(target) {
            return this.activeElement === target;
        },
        getScrollbarWidth() {
            // Creating invisible container
            const outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.overflow = 'scroll'; // forcing scrollbar to appear
            outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
            document.body.appendChild(outer);

            // Creating inner element and placing it in the container
            const inner = document.createElement('div');
            outer.appendChild(inner);

            // Calculating difference between container's full width and the child width
            const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

            // Removing temporary elements from the DOM
            outer.parentNode.removeChild(outer);

            return scrollbarWidth;
        },
    },
    computed: {
        isDesktop() {
            return this.width > 765;
        },
    },
});

Vue.directive('click-outside', ClickOutside);

Vue.filter('numeral', (value, format) => {
    return numeral(value).format(format);
});

Vue.directive('round', {
    inserted(el, binding, vnode) {
        el.addEventListener('change', function () {
            let mult = this.getAttribute('step');
            let count = this.value;

            if (!count || count < 0) {
                this.value = '';
                vnode.elm.dispatchEvent(new CustomEvent('input'));
                return;
            }

            if (Math.fmod(parseFloat(count), parseFloat(mult)) === 0) {
                return;
            }

            count = (Math.floor(count / mult) + 1) * mult;
            count = numeral(count).format('0[.][000]');

            this.value = count;

            vnode.elm.dispatchEvent(new CustomEvent('input'));
        });
    }
});

Vue.prototype.getUniqueId = () => {
    if (window.__vueUniqueId === undefined) {
        window.__vueUniqueId = 0;
    }

    return ++window.__vueUniqueId;
};

Vue.component('v-ons-toggle-sidebar-button', ToggleSidebarButton);

Vue.component('toolbar-popover-menu', ToolbarPopoverMenu);

Vue.component('toolbar-popover-menu-item', ToolbarPopoverMenuItem);

Vue.component('v-product-card', ProductCard);

if (ENV === 'prod') {
    Sentry.init({
        Vue,
        dsn: "https://4534b498b6d54481d62e38e172ac95c9@sentry.ditibal.com/3",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/sushiproduct\.com\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

new Vue({
    metaInfo() {
        let title = window.pageTitle ? window.pageTitle : window.shTitle;
        let description = window.pageDescription ? window.pageDescription : window.shDescription;
        let keywords = window.pageKeywords ? window.pageKeywords : window.shKeywords;

        return {
            title: title,
            meta: [
                {
                    name: 'description',
                    content: description,
                    vmid: 'description',
                },
                {
                    name: 'keywords',
                    content: keywords,
                    vmid: 'keywords',
                },
            ],
        };
    },
    router: router,
    render: h => h(App),
    created() {
        this.initYm();
    },
    methods: {
        initYm() {
            if (window.Y_DEBUG) {
                return;
            }

            (function(m,e,t,r,i,k,a) {
                m[i] = m[i] || function() { (m[i].a=m[i].a||[]).push(arguments); };
                m[i].l = 1 * new Date();
                k = e.createElement(t);
                a = e.getElementsByTagName(t)[0];
                k.async = 1;
                k.src = r;
                a.parentNode.insertBefore(k,a);
            })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

            ym(52291717, 'init', {
                id: 52291717,
                defer: true,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            });
        },
    },
}).$mount('#app');